import { Head, Image, Notes, Appear, Embed } from 'mdx-deck';
import { book } from 'mdx-deck/themes';
import Provider from "../../../../../../provider";
import i from "../../../../../../assets/402-cat.jpg";
import desktop from "../../../../../../assets/StatCounter-desktop-browser-ww-monthly-201405-201905.png";
import mobile from "../../../../../../assets/StatCounter-browser-ww-monthly-201405-201905.png";
import ius from "../../../../../../assets/StatCounter-browser-US-monthly-201405-201905.png";
import ijp from "../../../../../../assets/StatCounter-browser-JP-monthly-201405-201905.png";
import ilp from "../../../../../../assets/ilp_logo.svg";
import * as React from 'react';
export default {
  Head: Head,
  Image: Image,
  Notes: Notes,
  Appear: Appear,
  Embed: Embed,
  book: book,
  Provider: Provider,
  i: i,
  desktop: desktop,
  mobile: mobile,
  ius: ius,
  ijp: ijp,
  ilp: ilp,
  React: React
};